import "./ManageVoters.css";
import { Badge, Button, Card, Col, Container, Row, Spinner, Stack, Table } from "react-bootstrap";
import { faFileImport, faFilter, faPencil, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate, debounce } from "helpers/index";
import { useVoters } from "hooks/useVoters";
import { v4 as uuidv4 } from "uuid";
import FilterVoters from "./FilterVoters/FilterVoters";
import VoterUploadMapper from "./VoterUploadMapper/VoterUploadMapper";
import { useVariables } from "hooks/useVariables";
import VoterModal from "components/Modals/VoterModal/VoterModal";
import { exportVoterData } from "actions/voterActions";
import { useDispatch } from "react-redux";

function ManageVoters() {
    const dispatch = useDispatch();
    const [showUploadMapper, setShowUploadMapper] = useState(false);
    const [showFilterVoters, setShowFilterVoters] = useState(false);
    const [selectedVoter, setSelectedVoter] = useState(null);
    const [voterList, setVoterList] = useState([]);
    const [filters, setFilters] = useState({ page: 1 });
    const {
        isLoading,
        total,
        voters,
        votersCurrentPage,
        votersLastPage
    } = useVoters(filters);
    const { variables } = useVariables();
    const cardRef = useRef(null);
    const isLoadingRef = useRef(false);  // Track loading state with ref

    const handleFilterClose = useCallback((filters) => {
        setFilters({ ...filters, page: 1 });
        setVoterList([]);
        setShowFilterVoters(false);
    }, []);

    const handleExport = (filters) => {
        dispatch(exportVoterData(filters));
    };

    const handleImportVoters = useCallback(() => {
        setShowUploadMapper(true);
    }, []);

    const handleReset = useCallback(() => {
        setFilters({ page: 1 });
        setVoterList([]);
    }, []);

    const handleScroll = useCallback(() => {
        if (!cardRef.current || isLoadingRef.current) return;

        const { scrollHeight, clientHeight, scrollTop } = cardRef.current;
        const scrollThreshold = 100;
        
        const hasReachedThreshold = 
            scrollHeight - (clientHeight + scrollTop) <= scrollThreshold;

        if (hasReachedThreshold && votersCurrentPage < votersLastPage) {
            setFilters(prev => ({
                ...prev,
                page: prev.page + 1
            }));
        }
    }, [votersCurrentPage, votersLastPage]);

    // Attach scroll listener
    useEffect(() => {
        const currentRef = cardRef.current;
        if (currentRef) {
            const debouncedScroll = debounce(handleScroll, 150);
            currentRef.addEventListener('scroll', debouncedScroll);
            return () => {
                currentRef.removeEventListener('scroll', debouncedScroll);
                debouncedScroll.cancel();
            };
        }
    }, [handleScroll]);

    // Update loading ref when isLoading changes
    useEffect(() => {
        isLoadingRef.current = isLoading;
    }, [isLoading]);

    useEffect(() => {
        if (!voters) return;
    
        setVoterList(prevList => {
            if (filters.page === 1) {
                return voters;
            }
            
            // More thorough duplicate check
            const uniqueVoters = voters.filter(
                newVoter => !prevList.some(
                    existingVoter => existingVoter.voter_id === newVoter.voter_id
                )
            );
    
            const newList = [...prevList, ...uniqueVoters];
            
            // Ensure we don't exceed total
            return newList.slice(0, total);
        });
    }, [voters, filters.page, total]);

    const getResponse = (voter, variable) => {
        const responseIndex = voter.voter_responses.findIndex((res) => res.variable_id === variable.variable_id);
        if (responseIndex !== -1) {
            return voter.voter_responses[responseIndex].answer;
        } else {
            return '';
        };
    };

    return (
        <section id="manageVoters">
            <Container fluid>
                <FilterVoters show={showFilterVoters} onHide={handleFilterClose} onExport={handleExport} />
                <VoterUploadMapper show={showUploadMapper} onHide={() => setShowUploadMapper(false)} />
                {selectedVoter && <VoterModal show={selectedVoter} voterId={selectedVoter} onHide={() => setSelectedVoter(null)} />}
                <Row>
                    <Col>
                        <Card className="view">
                            <Card.Header>
                                <Stack className="d-flex justify-content-between" direction="horizontal" gap={2}>
                                    <div>
                                        <span>Manage Voters</span>{" "}
                                        <Badge bg="success" size="sm">{voterList.length}/{total}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleImportVoters}><FontAwesomeIcon icon={faFileImport} /></Button>{" "}
                                        {/* <Button variant="outline-success" size="sm"><FontAwesomeIcon icon={faPlus} /></Button>{" "} */}
                                        <Button variant="primary" size="sm" onClick={() => setShowFilterVoters(true)}><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper" ref={cardRef}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={10}>Personal Information</th>
                                            <th colSpan={variables.length}>Variables</th>
                                            <th className="sticky-col first-col" rowSpan={2}>Actions</th>
                                        </tr>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Sex</th>
                                            <th>Address</th>
                                            <th>Birthdate</th>
                                            <th>Province</th>
                                            <th>District</th>
                                            <th>Municipality/City</th>
                                            <th>Barangay</th>
                                            <th>Precinct</th>
                                            {
                                                variables.map((variable) => (
                                                    <th key={uuidv4()}>{variable.name}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voterList.map((voter, index) => (
                                                <tr key={voter.voter_id}>
                                                    <td>{index + 1}</td>
                                                    <td>{voter.name}</td>
                                                    <td>{voter.sex}</td>
                                                    <td>{voter.address}</td>
                                                    <td>{formatDate(voter.birthdate)}</td>
                                                    <td>{voter.province}</td>
                                                    <td>{voter.district}</td>
                                                    <td>{voter.municity}</td>
                                                    <td>{voter.barangay}</td>
                                                    <td>{voter.precinct}</td>
                                                    {
                                                        variables.map((variable) => (
                                                            <td key={uuidv4()}>{getResponse(voter, variable)}</td>
                                                        ))
                                                    }
                                                    <td className="sticky-col first-col">
                                                        <Button size="sm" onClick={() => setSelectedVoter(voter.voter_id)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <Stack gap={2} className="d-flex justify-content-center text-center">
                                    {isLoading && <Spinner animation="border" role="status" />}
                                    {!isLoading && voterList.length === 0 && <span>No voters found.</span>}
                                    {!isLoading && voterList.length > 0 && votersCurrentPage === votersLastPage && <span>End of List</span>}
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ManageVoters;