import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopulation } from 'actions/geopoliticalUnitActions';
import { geoPoliticalUnitSelector } from 'selectors/geopoliticalUnitSelector';

export function useGeopoliticalPopulation({...filters}) {
  const { province, district, municity, barangay, precinct } = filters;
  const dispatch = useDispatch();
  const {
    population,
    populationError
  } = useSelector(geoPoliticalUnitSelector);

  useEffect(() => {
    dispatch(getPopulation(province, district, municity, barangay, precinct));
  }, [dispatch, province, district, municity, barangay, precinct]);

  return {
    population,
    populationError
  };
}
