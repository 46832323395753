import { ADD_USER_FAILED, ADD_USER_SUCCESS, DELETE_USER, GET_USER_FAILED, GET_USER_SUCCESS, SHOW_USER, UPDATE_USER_FAILED, UPDATE_USER_SUCCESS, USER_LIST_LOAD_FAILED, USER_LIST_LOAD_REQUEST, USER_LIST_LOAD_SUCCESS, UPLOAD_USERS_FAILED, UPLOAD_USERS_SUCCESS, GET_USERS_REQUEST, GET_USER_REQUEST } from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    users: [],
    user: null,
    total: 0,
    lastPage: 0,
    currentPage: 0,
    isLoading: false,
    getUser: null
})

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case USER_LIST_LOAD_REQUEST:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case USER_LIST_LOAD_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                total: action.payload.total,
                lastPage: action.payload.lastPage,
                currentPage: action.payload.currentPage,
                error: null,
                isLoading: action.isLoading
            };
        case USER_LIST_LOAD_FAILED:
            return {
                ...state,
                users: [],
                error: action.payload.errors,
                isLoading: action.isLoading
            }
        case ADD_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                error: null
            };
        case ADD_USER_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case SHOW_USER:
            return {
                ...state,
                user: action.payload.user,
                error: action.error ? action.payload.errors : null
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                error: null
            };
        case UPDATE_USER_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case DELETE_USER:
            return {
                ...state,
                user: action.payload.user,
                error: action.error ? action.payload.errors : null
            };
        case UPLOAD_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                error: null
            };
        case UPLOAD_USERS_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                getUser: action.payload.user
            };
        case GET_USER_FAILED:
            return {
                ...state,
                getUser: null
            };
        case GET_USERS_REQUEST:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                users: action.payload.users,
                usersColumns: action.payload.usersColumns,
                usersCurrentPage: action.payload.usersCurrentPage,
                usersLastPage: action.payload.usersLastPage,
                usersError: action.payload.usersError,
                total: action.payload.total ? action.payload.total : state.total
            };
        case GET_USER_REQUEST:
            return {
                ...state,
                user: action.payload.user
            }
        default:
            return state;
    }
}

