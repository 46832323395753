import { useCallback, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, CartesianGrid, YAxis, XAxis, Tooltip, Legend, LabelList } from 'recharts';
import { useGenerateImage } from 'recharts-to-png';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import Spinner from 'react-bootstrap/Spinner';

const KpiGraph = ({ title = '', data, population, mode }) => {
	const date = new Date();
	const [chartGenerated] = useState({
		date: date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }),
		day: date.toLocaleDateString('en-GB', { weekday: 'long' }),
		time: date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
	});

	const formatMonth = (dateString) => { 
		const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month}'${year}`;
	}

	const formattedData = data?.map(item => ({
		...item,
		month: formatMonth(item.month),
		target: item.target.toFixed(2)
	}));

  const [getDivJpeg, { ref, isLoading }] = useGenerateImage({ quality: 0.8, type: 'image/jpeg' });
	const handleDownload = useCallback(async (e) => {
		e.preventDefault();

		const jpeg = await getDivJpeg();
		if (jpeg) {
			FileSaver.saveAs(jpeg, `${title}.jpeg`);
		}
	}, [getDivJpeg, title]);

	const renderCustomizedLabel = (props) => {
		const { x, y, width, height, target } = props;

		const filtered = formattedData.find(item => item.target === target)
		const percentage = (filtered.actual / filtered.target) * 100;
		const formattedPercentage = percentage === 100 || percentage === 0
			? Math.round(percentage)
			: percentage.toFixed(2);

		return (
			<text x={x + width + 10} y={y + 1.1 * height} fill='#FF0405' textAnchor='start' dominantBaseline='end'>
				{`${filtered.actual.toLocaleString()}, ${formattedPercentage}%`}
			</text>
		);
	};

	const generateRangeArray = (n, step = 25000) => {
		const result = [];
		for (let i = 0; i <= n; i += step) {
			result.push(parseInt(i));
		}
		return result;
	}

	const formatLegend = (value) => {
		return <span style={{ color: '#111' }}>{value.charAt(0).toUpperCase() + value.slice(1)}</span>;
	}

	return (
		<>
			<Table ref={ref}>
				<thead>
					<tr>
						<th style={{ width: '65%', textAlign: 'left' }}>
							<h6>{title}</h6>
						</th>
						<th style={{ width: '11%', textAlign: 'left' }}>PERF CAT.</th>
						<th style={{ width: '8%' }}>TARGET</th>
						<th style={{ width: '8%' }}>T/N (%)</th>
						<th style={{ width: '8%' }}>A/N (%)</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowSpan={formattedData?.length + 1}>
							<ResponsiveContainer minWidth={400} height={500}>
								<BarChart
									layout='vertical'
									data={formattedData}
									barCategoryGap={0}
									barGap={-4}
									barSize={20}
								>
									<CartesianGrid stroke='#f5f5f5' horizontal={false} />
									<XAxis type='number' scale='linear' domain={[0, population]} ticks={generateRangeArray(population, population / formattedData.length)} />
									<YAxis dataKey='month' type='month' scale='band'  padding={{ top: 20 }} />
									<Tooltip />
									<Legend align='left' wrapperStyle={{ left: 80 }} iconSize={28} formatter={formatLegend} />
									<Bar dataKey='target' barSize={25} fill='#010157' >
										<LabelList dataKey='actual' content={renderCustomizedLabel} />
									</Bar>
									<Bar dataKey='actual' barSize={15} fill='#FF0405' />
								</BarChart>
							</ResponsiveContainer>
						</td>
						<td style={{ color: '#20A4A5', fontWeight: 'bold', textAlign: 'left' }}>{formattedData[0]?.perf_cat.toUpperCase()}</td>
						<td style={{ color: '#302F59', fontWeight: 'bold', textDecoration: 'underline' }}>{formattedData[0]?.target.toLocaleString()}</td>
						<td>{formattedData[0]?.tn.toFixed(2)}</td>
						<td>{formattedData[0]?.an.toFixed(2)}</td>
					</tr>
					{formattedData?.length > 0 && [...Array(data.length - 1)].map((_, index) => (
						<tr key={index}>
							<td style={{ color: '#20A4A5', fontWeight: 'bold', textAlign: 'left' }}>{formattedData[index + 1]?.perf_cat.toUpperCase()}</td>
							<td style={{ color: '#302F59', fontWeight: 'bold', textDecoration: 'underline' }}>{formattedData[index + 1]?.target.toLocaleString()}</td>
							<td>{formattedData[index + 1]?.tn.toFixed(2)}</td>
							<td>{formattedData[index + 1]?.an.toFixed(2)}</td>
						</tr>
					))}
					{formattedData?.length > 0 && (
						<tr style={{ height: '70px' }}>
							<td colSpan={5}>
								<div className="text-end pe-3">
									<small style={{ color: '#010157', fontSize: '16px'}}>
										{chartGenerated.date} | {chartGenerated.day} | {chartGenerated.time}
									</small>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			{formattedData?.length > 0 && (
				<div className='text-end'>
					<button onClick={handleDownload} className='btn btn-sm btn-outline-success'>
						{isLoading ? <Spinner animation='border' size='sm' /> : <FontAwesomeIcon icon={faDownload} />}
					</button>
				</div>
			)}
		</>
	);
}

export default KpiGraph;
