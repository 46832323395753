import getURL from "api/config";
import { GET, POST, PATCH } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers/index";

export function getRoleListAction() {
    return function action(dispatch) {
        const url = getURL("GET_ROLE_LIST");
        const request = GET(url);

        request.then((response) => {
            const roles = response?.data?.roles || [];
            console.log(roles);

            dispatch({
                type: types.ROLE_LIST_LOAD_SUCCESS,
                payload: { roles }
            });
        }, (error) => {
            dispatch({
                type: types.ROLE_LIST_LOAD_FAILED,
                payload: { error }
            });
        });        
    };
}

export function addRoleAction(data) {
    return function action(dispatch) {
        const url = getURL("ADD_ROLE");
        const request = POST(url, data);

        request.then(() => {
            dispatch({
                type: types.ADD_ROLE_SUCCESS,
                payload: { error: null }
            });
        }, (error) => {
            dispatch({
                type: types.ADD_ROLE_FAILED,
                payload: { error }
            });
        });
    }
}

export function updateRoleAction(id, data) {
    return function action(dispatch) {
        const url = getURL("UPDATE_ROLE")(id);
        const request = PATCH(url, data);

        request.then(() => {
            dispatch({
                type: types.UPDATE_ROLE_SUCCESS,
                payload: { error: null }
            });
        }, (error) => {
            dispatch({
                type: types.UPDATE_ROLE_FAILED,
                payload: { error: getError(error) }
            });
        });
    }
};