import getURL from "api/config";
import { GET, PATCH, POST, DELETE } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";

export function getVariableListAction(queryParams) {
    return function action(dispatch) {
        const url = getURL("GET_VARIABLE_LIST");
        const request = GET(url, {}, {}, queryParams);

        request.then((response) => {
            const variables = response?.data?.result || {};
            dispatch({
                type: types.GET_VARIABLE_LIST_SUCCESS,
                payload: { variables }
            })
        }, (error) => {
            dispatch({
                type: types.GET_VARIABLE_LIST_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    };
}

export function getVariableAction(id) {
    return function action(dispatch) {
        const url = getURL("GET_VARIABLE")(id);
        const request = GET(url);

        request.then((response) => {
            const variable = response?.data?.result || {};
            dispatch({
                type: types.GET_VARIABLE_SUCCESS,
                payload: { variable }
            })
        }, (error) => {
            console.log(error)
            dispatch({
                type: types.GET_VARIABLE_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
}

export function addVariableAction(data) {
    return function action(dispatch) {
        const url = getURL("INSERT_VARIABLE");
        const request = POST(url, data);

        request.then((response) => {
            const variable = response?.data?.result.data || {};
            dispatch({
                type: types.ADD_VARIABLE_SUCCESS,
                payload: { variable }
            })
        }, (error) => {
            dispatch({
                type: types.ADD_VARIABLE_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
}

export function updateVariableAction(id, data) {
    return function action(dispatch) {
        const url = getURL("UPDATE_VARIABLE")(id);
        const request = PATCH(url, data);

        request.then((response) => {
            const variable = response?.data?.result.data || {};
            dispatch({
                type: types.UPDATE_VARIABLE_SUCCESS,
                payload: { variable }
            })
        }, (error) => {
            dispatch({
                type: types.UPDATE_VARIABLE_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
}

export function deleteVariableAction(id) {
    return function action(dispatch) {
        const url = getURL("DELETE_VARIABLE")(id);
        const request = DELETE(url);

        request.then((response) => {
            dispatch({
                type: types.DELETE_VARIABLE_SUCCESS
            })
        }, (error) => {
            dispatch({
                type: types.DELETE_VARIABLE_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
};

export function clearVariableErrorsAction() {
    return function action(dispatch) {
        dispatch({
            type: types.CLEAR_VARIABLE_ERRORS
        })
    }
};

export function updateVariableRankingsAction(variables) {
    return function action(dispatch) {
        const url = getURL("RANK_VARIABLES");
        const request = PATCH(url, variables);

        dispatch({
            type: types.RANK_VARIABLES_REQUEST
        });

        request.then((response) => {
            const variables = response?.data?.result;
            dispatch({
                type: types.RANK_VARIABLES_SUCCESS,
                payload: { variables }
            })
        }, (error) => {
            dispatch({
                type: types.RANK_VARIABLES_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
};