import "./ManageVariables.css";
import { Button, Card, Col, Row, Stack, Table, Badge, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getVariableListAction, updateVariableRankingsAction } from "actions/variableActions";
import { variableSelector } from "selectors/variableSelector";
import { useEffect, useState } from "react";
import VariableModal from "components/Modals/VariableModal/VariableModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faRotateLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { List, arrayMove } from "react-movable";
import LoadingOverlay from "components/common/LoadingOverlay";

function ManageVariables() {
    const dispatch = useDispatch();
    const { variables, isLoading } = useSelector(variableSelector);
    const [variableList, setVariableList] = useState([]);
    const [ showVariableModal, setShowVariableModal ] = useState({ show: false, variable: {} });
    const newVariable = {
        name: "",
        type: "Preference", 
        is_kpi: false,
        is_active: true,
        variable_options: []
    };

    const handleAddVariable = () => {
        setShowVariableModal({ show: true, variable: newVariable });
    };

    const handleReset = () => {
        setVariableList([]);
        dispatch(getVariableListAction());
    }

    const handleVariableModalClose = () => {
        setShowVariableModal({ show: false, variable: {} });
    };

    const updateVariableRank = (data) => {
        dispatch(updateVariableRankingsAction(data));
    }

    useEffect(() => {
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        setVariableList(variables);
    }, [variables]);

    return(
        <section id="manageVariables">            
            { showVariableModal.show && <VariableModal show={showVariableModal.show} onHide={handleVariableModalClose} variable_id={showVariableModal.variable.variable_id} /> }
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Stack direction="horizontal" gap={2} className="d-flex justify-content-between">
                                    <div>
                                        <span>Manage Variables</span>{" "}
                                        <Badge bg="success" size="sm">{variableList.length}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleAddVariable}><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper">
                                <LoadingOverlay 
                                    show={isLoading}
                                    message={'Reordering...'}
                                />
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th width="2%">No.</th>
                                            <th width="46%">Name</th>
                                            <th width="15%">Data Type</th>
                                            <th width="15%">KPI Type</th>                                            
                                            <th width="12%">Enabled</th>
                                            <th width="8%">Action</th>
                                        </tr>
                                    </thead>
                                    <List
                                        values={variables}
                                        onChange={({oldIndex, newIndex}) => updateVariableRank({variables: arrayMove(variables, oldIndex, newIndex)})}
                                        renderList={({children, props}) => <tbody {...props}>{children}</tbody>}
                                        renderItem={({value, props}) => <tr {...props}>
                                            <td>{value.rank + 1}</td>
                                            <td>{value.name}</td>
                                            <td>{value.type}</td>
                                            <td>{value.kpi_type || 'Not KPI'}</td>
                                            <td>{value.is_active ? 'Yes' : 'No'}</td>
                                            <td><Button variant="primary" size="sm" onClick={() => setShowVariableModal({ show: true, variable: value })}><FontAwesomeIcon icon={faPencil} /></Button></td>
                                        </tr>}
                                    />                             
                                </Table>                                    
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                
            </Container>
        </section>
    )
}

export default ManageVariables;