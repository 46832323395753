import {
  GET_KPIS_REQUEST,
  GET_KPIS_SUCCESS,
  GET_KPIS_FAILED,
  GET_KPI_REQUEST,
  GET_KPI_SUCCESS,
  GET_KPI_FAILED,
  ADD_KPI_REQUEST,
  ADD_KPI_SUCCESS,
  ADD_KPI_FAILED,
  GENERATE_KPI_GRAPH_REQUEST,
  GENERATE_KPI_GRAPH_SUCCESS,
  GENERATE_KPI_GRAPH_FAILED,
  UPDATE_KPI_REQUEST,
  UPDATE_KPI_SUCCESS,
  UPDATE_KPI_FAILED,
  DELETE_KPI_REQUEST,
  DELETE_KPI_SUCCESS,
  DELETE_KPI_FAILED
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  kpis: [],
  total: 0,
  kpi: null,
  error: null,
  isLoading: false,
});

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case GET_KPIS_REQUEST:
    case GET_KPI_REQUEST:
    case ADD_KPI_REQUEST:
    case GENERATE_KPI_GRAPH_REQUEST:
    case UPDATE_KPI_REQUEST:
    case DELETE_KPI_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case GET_KPIS_SUCCESS:
      return {
        ...state,
        kpis: action.payload.kpis,
        total: action.payload.total,
        isLoading: false,
        error: null
      };

    case GET_KPI_SUCCESS:
      return {
        ...state,
        kpi: action.payload.kpi,
        isLoading: false,
        error: null
      };

    case ADD_KPI_SUCCESS:
      return {
        ...state,
        kpi: action.payload.kpi,
        isLoading: false,
        error: null
      };

    case GENERATE_KPI_GRAPH_SUCCESS:
      return {
        ...state,
        kpi: action.payload.kpi,
        isLoading: false,
        error: null
      };

    case UPDATE_KPI_SUCCESS:
      return {
        ...state,
        // kpis: state.kpis.map(kpi =>
        //   kpi.id === action.payload.kpi.id ? action.payload.kpi : kpi
        // ),
        kpi: action.payload.kpi,
        isLoading: false,
        error: null
      };

    case DELETE_KPI_SUCCESS:
      return {
        ...state,
        kpis: state.kpis.filter(kpi => kpi.kpi_id !== action.payload.kpiId),
        isLoading: false,
        error: null
      };

    case GET_KPIS_FAILED:
    case GET_KPI_FAILED:
    case ADD_KPI_FAILED:
    case GENERATE_KPI_GRAPH_FAILED:
    case UPDATE_KPI_FAILED:
    case DELETE_KPI_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.errors
      };

    default:
      return state;
  }
}