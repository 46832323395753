import axios from "axios";
import { DEFAULT_HEADERS } from "constants/http";
import { persistor } from "store";

function getAccessToken() {
    return localStorage.getItem("access_token");
};

axios.interceptors.request.use(
    (config) => {
        const token = getAccessToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401 && window.location.pathname !== '/login') {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            persistor.purge();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export const GET = async (url, options = {}, headers = DEFAULT_HEADERS, queryParams = {}, data = {}) => {
    try {
        const urlWithParams = new URL(url);
        Object.keys(queryParams).forEach(key => urlWithParams.searchParams.append(key, queryParams[key]));
        
        return await axios.get(urlWithParams.toString(), {
            headers,
            data,
            ...options
        });
    } catch(error) {
        throw error;
    };
};

export const POST = async (url, payload, headers = DEFAULT_HEADERS) => {
    try {
        return await axios.post(url, payload, {
            headers
        });
    } catch(error) {
        throw error;
    };
};

export const PATCH = async(url, payload) => {
    try {
        return await axios.patch(url, payload, { headers: DEFAULT_HEADERS });
    } catch(error) {
        throw error;
    };
};

export const DELETE = async(url, payload = null) => {
    try {
        const options = {
            method: "DELETE",
            headers: DEFAULT_HEADERS
        };

        if (payload) {
            options.data = payload;
        };

        return await axios.delete(url, options);
    } catch(error) {
        throw error;
    };
};