export const ADD_PROVINCE_FAILED = 'ADD_PROVINCE_FAILED';
export const ADD_PROVINCE_SUCCESS = 'ADD_PROVINCE_SUCCESS';
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_VARIABLE_FAILED = "ADD_VARIABLE_FAILED";
export const ADD_VARIABLE_SUCCESS = "ADD_VARIABLE_SUCCESS";
export const ADD_VOTER_FAILED = "ADD_VOTER_FAILED";
export const ADD_VOTER_SUCCESS = "ADD_VOTER_SUCCESS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_PROVINCE_ERRORS = 'CLEAR_PROVINCE_ERRORS';
export const CLEAR_USER_ERRORS = "CLEAR_USER_ERRORS";
export const CLEAR_VARIABLE_ERRORS = "CLEAR_VARIABLE_ERRORS";
export const CLEAR_VOTER_ERRORS = "CLEAR_VOTER_ERRORS";
export const CREATE_USER = "CREATE_USER";
export const CREATE_VARIABLE_FAILED = "CREATE_VARIABLE_FAILED";
export const CREATE_VARIABLE_SUCCESS = "CREATE_VARIABLE_SUCCESS";
export const DELETE_PROVINCE = "DELETE_PROVINCE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_VARIABLE_FAILED = "DELETE_VARIABLE_FAILED";
export const DELETE_VARIABLE_SUCCESS = "DELETE_VARIABLE_SUCCESS";
export const DELETE_VOTER = "DELETE_VOTER";
export const GET_VARIABLE_FAILED = "GET_VARIABLE_FAILED";
export const GET_VARIABLE_LIST_FAILED = "GET_VARIABLE_LIST_FAILED";
export const GET_VARIABLE_LIST_SUCCESS = "GET_VARIABLE_LIST_SUCCESS";
export const GET_VARIABLE_SUCCESS = "GET_VARIABLE_SUCCESS";
export const RANK_VARIABLES_REQUEST = "RANK_VARIABLES_REQUEST";
export const RANK_VARIABLES_SUCCESS = "RANK_VARIABLES_SUCCESS";
export const RANK_VARIABLES_FAILED = "RANK_VARIABLES_FAILED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const PROVINCE_LIST_LOAD_FAILED = 'PROVINCE_LIST_LOAD_FAILED';
export const PROVINCE_LIST_LOAD_SUCCESS = 'PROVINCE_LIST_LOAD_SUCCESS';
export const SEND_REQUEST_FOR_DEMO_EMAIL_FAILED = "SEND_REQUEST_FOR_DEMO_EMAIL_FAILED";
export const SEND_REQUEST_FOR_DEMO_EMAIL_SUCCESS = "SEND_REQUEST_FOR_DEMO_EMAIL_SUCCESS";
export const SHOW_PROVINCE = "SHOW_PROVINCE";
export const SHOW_USER = "SHOW_USER";
export const SHOW_VOTER = "SHOW_VOTER";
export const UPDATE_PROVINCE_FAILED = 'UPDATE_PROVINCE_FAILED';
export const UPDATE_PROVINCE_SUCCESS = 'UPDATE_PROVINCE_SUCCESS';
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_VARIABLE_FAILED = "UPDATE_VARIABLE_FAILED";
export const UPDATE_VARIABLE_SUCCESS = "UPDATE_VARIABLE_SUCCESS";
export const UPDATE_VOTER_FAILED = "UPDATE_VOTER_FAILED";
export const UPDATE_VOTER_SUCCESS = "UPDATE_VOTER_SUCCESS";
export const UPLOAD_VOTERS_FAILED = "UPLOAD_VOTERS_FAILED";
export const UPLOAD_VOTERS_SUCCESS = "UPLOAD_VOTERS_SUCCESS";
export const USER_LIST_LOAD_FAILED = "USER_LIST_LOAD_FAILED";
export const USER_LIST_LOAD_SUCCESS = "USER_LIST_LOAD_SUCCESS";
export const VOTER_LIST_LOAD_FAILED = "VOTER_LIST_LOAD_FAILED";
export const VOTER_LIST_LOAD_SUCCESS = "VOTER_LIST_LOAD_SUCCESS";
export const MUNICIPALITY_LIST_LOAD_FAILED = "MUNICIPALITY_LIST_LOAD_FAILED";
export const MUNICIPALITY_LIST_LOAD_SUCCESS = "MUNICIPALITY_LIST_LOAD_SUCCESS";
export const ADD_MUNICIPALITY_FAILED = "ADD_MUNICIPALITY_FAILED";
export const ADD_MUNICIPALITY_SUCCESS = "ADD_MUNICIPALITY_SUCCESS";
export const DELETE_MUNICIPALITY = "DELETE_MUNICIPALITY";
export const SHOW_MUNICIPALITY = "SHOW_MUNICIPALITY";
export const UPDATE_MUNICIPALITY_FAILED = "UPDATE_MUNICIPALITY_FAILED";
export const UPDATE_MUNICIPALITY_SUCCESS = "UPDATE_MUNICIPALITY_SUCCESS";
export const CLEAR_MUNICIPALITY_ERRORS = "CLEAR_MUNICIPALITY_ERRORS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FILTER_VOTER_LIST_FAILED = "FILTER_VOTER_LIST_FAILED";
export const FILTER_VOTER_LIST_SUCCESS = "FILTER_VOTER_LIST_SUCCESS";
export const ROLE_LIST_LOAD_SUCCESS = "ROLE_LIST_LOAD_SUCCESS";
export const ROLE_LIST_LOAD_FAILED = "ROLE_LIST_LOAD_FAILED";
export const VOTER_LIST_LOAD_REQUEST = "VOTER_LIST_LOAD_REQUEST";
export const ADD_VARIABLE_OPTION_FAILED = "ADD_VARIABLE_OPTION_FAILED";
export const ADD_VARIABLE_OPTION_SUCCESS = "ADD_VARIABLE_OPTION_SUCCESS";
export const UPDATE_VARIABLE_OPTION_FAILED = "UPDATE_VARIABLE_OPTION_FAILED";
export const UPDATE_VARIABLE_OPTION_SUCCESS = "UPDATE_VARIABLE_OPTION_SUCCESS";
export const UPLOAD_USERS_FAILED = "UPLOAD_USERS_FAILED";
export const UPLOAD_USERS_SUCCESS = "UPLOAD_USERS_SUCCESS";
export const USER_LIST_LOAD_REQUEST = "USER_LIST_LOAD_REQUEST";
export const UPLOAD_VOTERS_REQUEST = "UPLOAD_VOTERS_REQUEST";
export const UPDATE_VOTER_REQUEST = "UPDATE_VOTER_REQUEST";
export const UPLOAD_USERS_REQUEST = "UPLOAD_USERS_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILED = "UPDATE_ROLE_FAILED";
export const PROVINCE_LIST_LOAD_REQUEST = "PROVINCE_LIST_LOAD_REQUEST";
export const MUNICIPALITY_LIST_LOAD_REQUEST = "MUNICIPALITY_LIST_LOAD_REQUEST";
export const BARANGAY_LIST_LOAD_REQUEST = "BARANGAY_LIST_LOAD_REQUEST";
export const BARANGAY_LIST_LOAD_SUCCESS = "BARANGAY_LIST_LOAD_SUCCESS";
export const BARANGAY_LIST_LOAD_FAILED = "BARANGAY_LIST_LOAD_FAILED";
export const PRECINCT_LIST_LOAD_REQUEST = "PRECINCT_LIST_LOAD_REQUEST";
export const PRECINCT_LIST_LOAD_SUCCESS = "PRECINCT_LIST_LOAD_SUCCESS";
export const PRECINCT_LIST_LOAD_FAILED = "PRECINCT_LIST_LOAD_FAILED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const PROVINCES_LOAD_REQUEST = "PROVINCES_LOAD_REQUEST";
export const PROVINCES_LOAD_SUCCESS = "PROVINCES_LOAD_SUCCESS";
export const PROVINCES_LOAD_FAILED = "PROVINCES_LOAD_FAILED";
export const DISTRICTS_LOAD_REQUEST = "DISTRICTS_LOAD_REQUEST";
export const DISTRICTS_LOAD_SUCCESS = "DISTRICTS_LOAD_SUCCESS";
export const DISTRICTS_LOAD_FAILED = "DISTRICTS_LOAD_FAILED";
export const MUNI_CITIES_LOAD_REQUEST = "MUNI_CITIES_LOAD_REQUEST";
export const MUNI_CITIES_LOAD_SUCCESS = "MUNI_CITIES_LOAD_SUCCESS";
export const MUNI_CITIES_LOAD_FAILED = "MUNI_CITIES_LOAD_FAILED";
export const BARANGAYS_LOAD_REQUEST = "BARANGAYS_LOAD_REQUEST";
export const BARANGAYS_LOAD_SUCCESS = "BARANGAYS_LOAD_SUCCESS";
export const BARANGAYS_LOAD_FAILED = "BARANGAYS_LOAD_FAILED";
export const PRECINCTS_LOAD_REQUEST = "PRECINCTS_LOAD_REQUEST";
export const PRECINCTS_LOAD_SUCCESS = "PRECINCTS_LOAD_SUCCESS";
export const PRECINCTS_LOAD_FAILED = "PRECINCTS_LOAD_FAILED";
export const POPULATION_LOAD_REQUEST = "POPULATION_LOAD_REQUEST";
export const POPULATION_LOAD_SUCCESS = "POPULATION_LOAD_SUCCESS";
export const POPULATION_LOAD_FAILED = "POPULATION_LOAD_FAILED";
export const GET_VOTERS_REQUEST = "GET_VOTERS_REQUEST";
export const GET_VOTERS_SUCCESS = "GET_VOTERS_SUCCESS";
export const GET_VOTERS_FAILED = "GET_VOTERS_FAILED";
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_VOTER_REQUEST = "GET_VOTER_REQUEST";
export const GET_VOTER_DISTRIBUTION_REQUEST = "GET_VOTER_DISTRIBUTION_REQUEST";

export const GET_KPIS_REQUEST = "GET_KPIS_REQUEST";
export const GET_KPIS_SUCCESS = "GET_KPIS_SUCCESS";
export const GET_KPIS_FAILED = "GET_KPIS_FAILED";
export const GET_KPI_REQUEST = "GET_KPI_REQUEST";
export const GET_KPI_SUCCESS = "GET_KPI_SUCCESS";
export const GET_KPI_FAILED = "GET_KPI_FAILED";
export const ADD_KPI_REQUEST = "ADD_KPI_REQUEST";
export const ADD_KPI_SUCCESS = "ADD_KPI_SUCCESS";
export const ADD_KPI_FAILED = "ADD_KPI_FAILED";
export const GENERATE_KPI_GRAPH_REQUEST = "GENERATE_KPI_GRAPH_REQUEST";
export const GENERATE_KPI_GRAPH_SUCCESS = "GENERATE_KPI_GRAPH_SUCCESS";
export const GENERATE_KPI_GRAPH_FAILED = "GENERATE_KPI_GRAPH_FAILED";
export const UPDATE_KPI_REQUEST = "UPDATE_KPI_REQUEST";
export const UPDATE_KPI_SUCCESS = "UPDATE_KPI_SUCCESS";
export const UPDATE_KPI_FAILED = "UPDATE_KPI_FAILED";
export const DELETE_KPI_REQUEST = "DELETE_KPI_REQUEST";
export const DELETE_KPI_SUCCESS = "DELETE_KPI_SUCCESS";
export const DELETE_KPI_FAILED = "DELETE_KPI_FAILED";