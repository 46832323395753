import { ADD_VARIABLE_FAILED, ADD_VARIABLE_SUCCESS, GET_VARIABLE_LIST_SUCCESS, GET_VARIABLE_LIST_FAILED, GET_VARIABLE_SUCCESS, GET_VARIABLE_FAILED, UPDATE_VARIABLE_SUCCESS, UPDATE_VARIABLE_FAILED, DELETE_VARIABLE_SUCCESS, DELETE_VARIABLE_FAILED, RANK_VARIABLES_FAILED, RANK_VARIABLES_SUCCESS, RANK_VARIABLES_REQUEST } from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    variables: [],
    variable: null
})

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case GET_VARIABLE_LIST_SUCCESS:
            return {
                ...state,
                variables: action.payload.variables,
                error: null
            };
        case GET_VARIABLE_LIST_FAILED:
            return {
                ...state,
                variables: [],
                error: action.payload.errors
            };
        case GET_VARIABLE_SUCCESS:
            return {
                ...state,
                variable: action.payload.variable,
                error: null
            };
        case GET_VARIABLE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case ADD_VARIABLE_SUCCESS:
            return {
                ...state,
                variable: action.payload.variable,
                error: null
            };
        case ADD_VARIABLE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case UPDATE_VARIABLE_SUCCESS:
            return {
                ...state,
                variable: action.payload.variable,
                error: null
            };
        case UPDATE_VARIABLE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case DELETE_VARIABLE_SUCCESS:
            return {
                ...state,
                variable: null,
                error: null
            };
        case DELETE_VARIABLE_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case RANK_VARIABLES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case RANK_VARIABLES_SUCCESS:
            return {
                ...state,
                variables: action.payload.variables,
                isLoading: false
            };
        case RANK_VARIABLES_FAILED:
            return {
                ...state,
                error: action.payload.errors,
                isLoading: false
            };
        default:
            return state;
    }
}