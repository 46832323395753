import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function VariableFilter(params) {
    const { variable, filters, onChange } = params;

    return(
        <>
            {
                variable ? <Form.Group>
                <Form.Label>{variable.name}</Form.Label>
                <Form.Select
                    name={variable.name}
                    onChange={onChange}
                    value={filters[variable.name] || ''}
                >
                    <option value=''>Select one</option>
                    {
                        variable.variable_options.map((option) => (
                            <option key={uuidv4()} value={option.name}>{option.name}</option>
                        ))
                    }
                </Form.Select>
            </Form.Group> : ''
            }
        </>
    )
}

export default VariableFilter;