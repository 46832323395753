import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kpiSelector } from 'selectors/kpiSelector';
import { deleteKpiAction, getKpisAction } from 'actions/kpiActions';
import { Table, Stack } from 'react-bootstrap';
import LoadingSpinner from 'components/common/LoadingSpinner';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import TableRow from './TableRow';
import DeleteModal from './DeleteModal';

import './index.css';

function KpiList() {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [kpiToDelete, setKpiToDelete] = useState(null);
  const { kpis, isLoading } = useSelector(kpiSelector);
  const [alertConfig, setAlertConfig] = useState({
    heading: '',
    message: '',
    variant: 'danger',
    show: false,
    dismissible: true // Set default value here
  });

  const showAlert = useCallback((heading, message, variant = 'danger') => {
    setAlertConfig({
      heading,
      message,
      variant,
      dismissible: true,
      show: true
    });
  }, []);

  const handleAlertClose = useCallback(() => {
    setAlertConfig((prev) => ({ ...prev, show: false }));
  }, []);

  useEffect(() => {
    const fetchKpis = () => {
      try {
        dispatch(getKpisAction());
      } catch (error) {
        showAlert('Something went wrong', error.message || 'Failed to fetch KPIs');
      }
    };

    fetchKpis();
  }, [dispatch, showAlert]);

  const handleDeleteClick = useCallback((kpi) => {
    setKpiToDelete(kpi);
    setShowDeleteModal(true);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    try {
      dispatch(deleteKpiAction(kpiToDelete.kpi_id));
      showAlert('Success', 'KPI deleted successfully', 'success');
    } catch (error) {
      showAlert('Something went wrong', error.message || 'Failed to delete KPI');
    } finally {
      setShowDeleteModal(false);
      setKpiToDelete(null);
    }
  }, [dispatch, kpiToDelete, showAlert]);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteModal(false);
    setKpiToDelete(null);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <section id='kpiList'>
      <AlertMessage
        heading={alertConfig.heading}
        message={alertConfig.message}
        variant={alertConfig.variant}
        visibility={alertConfig.show}
        onClose={handleAlertClose}
        dismissible={alertConfig.dismissible}
      />

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th width="2%">#</th>
            <th width="40%">KPI</th>
            <th width="10%">Place</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Target Value</th>
            <th>Percentage Value</th>
            <th width="20%">Criteria</th>
            <th width="8%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {kpis.map((kpi, index) => TableRow(kpi, index, handleDeleteClick))}
        </tbody>
      </Table>

      <Stack gap={2} className='d-flex justify-content-center text-center'>
        {isLoading && <LoadingSpinner />}
        {!isLoading && kpis.length === 0 && <span>No KPI found.</span>}
      </Stack>

      <DeleteModal
        show={showDeleteModal}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        kpiName={kpiToDelete?.kpi_name}
      />
    </section>
  );
}

export default KpiList;
