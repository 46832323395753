import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kpiSelector } from 'selectors/kpiSelector';
import { addKpiAction, generateKpiGraphAction } from 'actions/kpiActions';
import { useNavigate } from 'react-router-dom';
import KpiForm from './KpiForm';
import AlertMessage from 'components/AlertMessage/AlertMessage';

const CreateKpi = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { kpi, isLoading } = useSelector(kpiSelector);
  const [alertConfig, setAlertConfig] = useState({
    heading: '',
    message: '',
    variant: 'danger',
    show: false,
    dismissible: true // Set default value here
  });

  const showAlert = useCallback((heading, message, variant = 'danger') => {
    setAlertConfig({
      heading,
      message,
      variant,
      dismissible: true,
      show: true
    });
  }, []);

  const handleAlertClose = useCallback(() => {
    setAlertConfig((prev) => ({ ...prev, show: false }));
  }, []);

  const handleSubmit = async (data) => {
    try {
      if (data.is_save === 'true') {
        dispatch(addKpiAction(data));
      } else {
        dispatch(generateKpiGraphAction(data));
      }
    } catch (error) {
      showAlert('Something went wrong', error.message || 'Failed to create KPI');
    }
  }

  useEffect(() => {
    if (kpi?.kpi_id) {
      navigate(`/cms/kpis/${kpi.kpi_id}`);
    }
  }, [navigate, kpi]);

  return (
    <section id='createKpi'>
      <AlertMessage
        heading={alertConfig.heading}
        message={alertConfig.message}
        variant={alertConfig.variant}
        visibility={alertConfig.show}
        onClose={handleAlertClose}
        dismissible={alertConfig.dismissible}
      />

      <KpiForm kpi={kpi} onSubmit={handleSubmit} isLoading={isLoading} />
    </section>
  );
}

export default CreateKpi;
