import getURL from "api/config";
import { GET } from "api/index";
import {
    PROVINCES_LOAD_REQUEST, PROVINCES_LOAD_SUCCESS, PROVINCES_LOAD_FAILED,
    DISTRICTS_LOAD_REQUEST, DISTRICTS_LOAD_SUCCESS, DISTRICTS_LOAD_FAILED,
    MUNI_CITIES_LOAD_REQUEST, MUNI_CITIES_LOAD_SUCCESS, MUNI_CITIES_LOAD_FAILED,
    BARANGAYS_LOAD_REQUEST, BARANGAYS_LOAD_SUCCESS, BARANGAYS_LOAD_FAILED,
    PRECINCTS_LOAD_REQUEST, PRECINCTS_LOAD_SUCCESS, PRECINCTS_LOAD_FAILED,
    POPULATION_LOAD_REQUEST, POPULATION_LOAD_SUCCESS, POPULATION_LOAD_FAILED
} from "constants/actionTypes";

export function getProvinces() {
    return function action(dispatch) {
        const url = getURL("GET_PROVINCES");
        const request = GET(url);

        dispatch({
            type: PROVINCES_LOAD_REQUEST,
            payload: { provinces: [] }
        });

        request.then((response) => {
            const provinces = response.data.result;
            dispatch({
                type: PROVINCES_LOAD_SUCCESS,
                payload: { provinces }
            });
        }).catch((error) => {
            dispatch({
                type: PROVINCES_LOAD_FAILED,
                payload: error
            });
        });
    };
};

export function getDistricts(province) {
    return function action(dispatch) {
        const url = getURL("GET_DISTRICTS");
        const params = province ? { province } : {};
        const request = GET(url, {}, {}, params);

        dispatch({
            type: DISTRICTS_LOAD_REQUEST,
            payload: { districts: [] }
        });

        request.then((response) => {
            const districts = response.data.result;
            dispatch({
                type: DISTRICTS_LOAD_SUCCESS,
                payload: { districts }
            });
        }).catch((error) => {
            dispatch({
                type: DISTRICTS_LOAD_FAILED,
                payload: error
            });
        });
    };
};

export function getMuniCities(province, district) {
    return function action(dispatch) {
        const url = getURL("GET_MUNI_CITIES");
        const params = (province && district) ? { province, district } : {};
        const request = GET(url, {}, {}, params);

        dispatch({
            type: MUNI_CITIES_LOAD_REQUEST,
            payload: { muniCities: [] }
        });

        request.then((response) => {
            const muniCities = response.data.result;
            dispatch({
                type: MUNI_CITIES_LOAD_SUCCESS,
                payload: { muniCities }
            });
        }).catch((error) => {
            dispatch({
                type: MUNI_CITIES_LOAD_FAILED,
                payload: error
            });
        });
    };
};

export function getBarangays(province, district, municity) {
    return function action(dispatch) {
        const url = getURL("GET_BARANGAYS");
        const params = (province && district && municity) ? { province, district, municity } : {};
        const request = GET(url, {}, {}, params);

        dispatch({
            type: BARANGAYS_LOAD_REQUEST,
            payload: { barangays: [] }
        });

        request.then((response) => {
            const barangays = response.data.result;
            dispatch({
                type: BARANGAYS_LOAD_SUCCESS,
                payload: { barangays }
            });
        }).catch((error) => {
            dispatch({
                type: BARANGAYS_LOAD_FAILED,
                payload: error
            });
        });
    };
};

export function getPrecincts(province, district, municity, barangay) {
    return function action(dispatch) {
        const url = getURL("GET_PRECINCTS");
        const params = (province && district && municity && barangay) ? { province, district, municity, barangay } : {};
        const request = GET(url, {}, {}, params);

        dispatch({
            type: PRECINCTS_LOAD_REQUEST,
            payload: { precincts: [] }
        });

        request.then((response) => {
            const precincts = response.data.result;
            dispatch({
                type: PRECINCTS_LOAD_SUCCESS,
                payload: { precincts }
            });
        }).catch((error) => {
            dispatch({
                type: PRECINCTS_LOAD_FAILED,
                payload: error
            });
        });
    };
};

export function getPopulation(province, district, municity, barangay, precinct) {
    return function action(dispatch) {
        const url = getURL("GET_POPULATION");
        const params = { province, district, municity, barangay, precinct };
        const request = GET(url, {}, {}, params);

        dispatch({
            type: POPULATION_LOAD_REQUEST,
            payload: { population: 0 }
        });

        request.then((response) => {
            const population = response.data.result;
            dispatch({
                type: POPULATION_LOAD_SUCCESS,
                payload: { population }
            });
        }).catch((error) => {
            dispatch({
                type: POPULATION_LOAD_FAILED,
                payload: error
            });
        });
    }
}
