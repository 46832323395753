import "./FilterVoters.css";
import { Button, Col, Modal, Container, Form, Row, Stack } from "react-bootstrap";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { SEXES } from "constants/common";
import { useVariables } from "hooks/useVariables";
import VariableFilter from "./VariableFilter";
import { v4 as uuidv4 } from "uuid";
import { getVariableAction } from "actions/variableActions";
import { useDispatch, useSelector } from "react-redux";
import { variableSelector } from "selectors/variableSelector";

function FilterVoters({ show, onHide, onExport }) {
    const [filters, setFilters] = useState({});
    const filterDeps = useMemo(() => ({
        province: filters.province,
        district: filters.district,
        muniCity: filters.municity,
        barangay: filters.barangay,
        precinct: filters.precinct
    }), [
        filters.province,
        filters.district,
        filters.municity,
        filters.barangay,
        filters.precinct
    ]);
    const { provinces, districts, muniCities, precincts, barangays } = useGeopoliticalUnits(filterDeps);
    const { variables } = useVariables();
    const [selectedVariables, setSelectedVariables] = useState([]);
    const { variable } = useSelector(variableSelector);
    const dispatch = useDispatch();

    const handleFilterChange = useCallback((e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }, [filters]);

    const handleClose = () => {
        setFilters({});
        onHide();
    };

    const isFieldDisabled = useCallback((fieldName) => {
        switch (fieldName) {
            case 'district':
                return !filters.province;
            case 'municity':
                return !filters.district;
            case 'barangay':
                return !filters.municity;
            case 'precinct':
                return !filters.barangay;
            default:
                return false;
        }
    }, [filters]);

    const LocationSection = useMemo(() => {
        return (
            <>
                <Form.Group>
                    <Form.Label>Province</Form.Label>
                    <Form.Select
                        name="province"
                        value={filters.province || ''}
                        onChange={handleFilterChange}
                    >
                        <option value="">All</option>
                        {
                            provinces.map((option) => (
                                <option key={uuidv4()} value={option}>{option}</option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>

                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>
                            District
                        </Form.Label>
                        <Form.Select
                            name="district"
                            value={filters.district || ''}
                            onChange={handleFilterChange}
                            disabled={isFieldDisabled('district')}
                        >
                            <option value="">{filters.province ? 'All' : 'Select a province first'}</option>
                            {
                                districts.map((option) => (
                                    <option key={uuidv4()} value={option}>{option}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Municipality/City
                        </Form.Label>
                        <Form.Select
                            name="municity"
                            value={filters.municity || ''}
                            onChange={handleFilterChange}
                            disabled={isFieldDisabled('municity')}
                        >
                            <option value="">{filters.district ? 'All' : 'Select a district first'}</option>
                            {
                                muniCities.map((option) => (
                                    <option key={uuidv4()} value={option}>{option}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Barangay
                        </Form.Label>
                        <Form.Select
                            name="barangay"
                            value={filters.barangay || ''}
                            onChange={handleFilterChange}
                            disabled={isFieldDisabled('barangay')}
                        >
                            <option value="">{filters.municity ? 'All' : 'Select a municipality/city first'}</option>
                            {
                                barangays.map((option) => (
                                    <option key={uuidv4()} value={option}>{option}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>
                            Precinct
                        </Form.Label>
                        <Form.Select
                            name="precinct"
                            value={filters.precinct || ''}
                            onChange={handleFilterChange}
                            disabled={isFieldDisabled('precinct')}
                        >
                            <option value="">{filters.barangay ? 'All' : 'Select a barangay first'}</option>
                            {
                                precincts.map((option) => (
                                    <option key={uuidv4()} value={option}>{option}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </Row>
            </>
        )
    }, [handleFilterChange, isFieldDisabled, filters, provinces, districts, muniCities, barangays, precincts])

    const addVariableFilter = (e) => {
        dispatch(getVariableAction(e.target.value))        
    };

    useEffect(() => {
        setSelectedVariables((prev) => prev.concat(variable))
    }, [variable]);

    return (
        <Modal id="filterVoters" show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Filter Voters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Stack gap={2}>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={filters.name || ''}
                                    placeholder="Name"
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Sex</Form.Label>
                                <Form.Select
                                    name="sex"
                                    value={filters.sex || ''}
                                    onChange={handleFilterChange}>
                                    <option value="">All</option>
                                    {
                                        SEXES.map((option) => (
                                            <option key={uuidv4()} value={option === 'Male' ? 'M' : 'F'}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Birthdate</Form.Label>
                                <Form.Control
                                    name="birthdate"
                                    type="date"
                                    value={filters.birthdate || ''}
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={filters.address || ''}
                                placeholder="Address"
                                onChange={handleFilterChange}
                            />
                        </Form.Group>
                        {LocationSection}
                        <Form.Group>
                            <Form.Label>Add Variables</Form.Label>
                            <Form.Select onChange={(e) => addVariableFilter(e)}>
                                <option>Select a variable to add filter</option>
                                {
                                    variables.map((x) => (
                                        <option key={x.variable_id} value={x.variable_id}>{x.name}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Row>
                            {
                                selectedVariables?.map((variable) => (
                                    <VariableFilter key={uuidv4()} filters={filters} variable={variable} onChange={handleFilterChange}/>

                                ))
                            }
                        </Row>
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onHide(filters)}>Filter</Button>
                <Button variant="success" onClick={() => onExport(filters)}>Export</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterVoters;