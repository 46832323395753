import './Header.css'
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Button, Image, Stack, Container, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { logoutAction } from 'actions/sessionActions';
import { getUser } from 'actions/userActions';
import logo from 'assets/logo.png';
import { sessionSelector } from 'selectors/sessionSelector';
import { userSelector } from 'selectors/userSelector';
import { toggleSidebarAction } from 'actions/sidebarActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import VoterModal from 'components/Modals/VoterModal/VoterModal';

function Header() {
  const { userId } = useSelector(sessionSelector);
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showVoterModal, setShowVoterModal] = useState(false);
  const prevUser  = useRef(user)

  const logOutUser = useCallback(() => dispatch(logoutAction()), [dispatch]);

  const isAuthPage = useMemo(() => ['/', '/login', '/request-for-demo'].includes(pathname), [pathname]);

  const handleToggleSidebar = useCallback(() => dispatch(toggleSidebarAction()), [dispatch]);

  const handleModalClose = useCallback(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
    setShowVoterModal(false);
  }, [dispatch, userId]);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      prevUser.current = user;
    }
  }, [user]);

  return (
    <header className="header">
      {showVoterModal && <VoterModal show={showVoterModal} voterId={user?.voter_id} onHide={handleModalClose} />}
      <Navbar expand="lg">
        <Container fluid>
          <Stack className="w-100" direction="horizontal" gap={3}>
            <Navbar.Brand as={NavLink}>
              <FontAwesomeIcon color="white" icon={faBars} className="me-2" onClick={handleToggleSidebar}/>
              <Image src={logo} height={30} alt="Logo"/>
            </Navbar.Brand>
            <div className="d-flex justify-content-end w-100">
              {(user || prevUser.current) && !isAuthPage ? (
                <Stack direction="horizontal" gap={3}>
                  {user?.voter_id ?
                    <NavDropdown title={(user || prevUser.current).name}>
                      <NavDropdown.Item onClick={() => setShowVoterModal(true)}>
                        My Profile
                      </NavDropdown.Item>
                    </NavDropdown> :
                    <span className="text-white">
                      {user?.name}
                    </span>
                  }
                  <Button variant="link" onClick={logOutUser}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger" />
                  </Button>
                </Stack>
              ) : (
                <NavLink to="/login" className="btn btn-primary">Login</NavLink>
              )}
            </div>
          </Stack>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;