import "./VoterUploadMapper.css";
import { Button, Modal, Form, Row, Col, Stack, Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { read, utils, write } from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { VOTER_UPLOAD_HEADERS } from "constants/common";
import { uploadVotersAction, getVoterListAction } from "actions/voterActions";
import { getVariableListAction } from "actions/variableActions";
import { voterSelector } from "selectors/voterSelector";
import { variableSelector } from "selectors/variableSelector";
import { UPLOAD_VOTERS_REQUEST } from "constants/actionTypes";
import { toSnakeCase } from "helpers";

function VoterUploadMapper({ show, onHide }) {
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [mappedHeaders, setMappedHeaders] = useState([]);
    const [sheetHeaders, setSheetHeaders] = useState([]);
    const [data, setData] = useState([]);
    const { uploadError } = useSelector(voterSelector);
    const { variables } = useSelector(variableSelector);

    const dispatch = useDispatch();

    const getExistingHeaders = () => {
        return VOTER_UPLOAD_HEADERS.concat(variables.map((variable) => toSnakeCase(variable.name.toLowerCase())));
    }

    const handleFileUpload = async () => {
        setIsLoading(true);
        const file = inputRef.current.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            const bufferArray = e.target.result;
            const workbook = read(bufferArray, { dense: true, type: 'buffer' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = utils.sheet_to_json(sheet, { header: 1 });
            setData(data);
            setSheetHeaders(data[0]);
            setMappedHeaders(data[0]);
        };
    };

    const handleClose = () => {
        setData([]);
        setSheetHeaders([]);
        setMappedHeaders(getExistingHeaders());
        dispatch({
            type: UPLOAD_VOTERS_REQUEST,
            payload: { isLoading: false, uploadError: false }
        })
        onHide();
    };

    const handleColumnMapping = (e, index) => {
        const newMappedHeaders = [...mappedHeaders];
        newMappedHeaders[index] = e.target.value;
        setMappedHeaders(newMappedHeaders);
    };

    const handleImport = async () => {
        setIsLoading(true);
        const dataOnly = data.slice(1); // Skip header row
        let batchNumber = 0;
        while (!uploadError && dataOnly.length > 0) {
            batchNumber += 1;
            const newWorkbook = utils.book_new();
            const batch = dataOnly.splice(0, 30);
            if (batch[0].length === 0) {
                break;
            }
            const newSheet = utils.aoa_to_sheet([mappedHeaders, ...batch]);
            utils.book_append_sheet(newWorkbook, newSheet, batchNumber.toString());
            const newWorkBookData = write(newWorkbook, { bookType: 'xlsx', type: 'array' });
            const newFile = new File([newWorkBookData], `${batchNumber}.xlsx`);
            const formData = new FormData();
            formData.append('file', newFile);
            dispatch(uploadVotersAction(formData));
            // Wait for 5 seconds before processing the next batch
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        setIsLoading(false);
        dispatch(getVoterListAction());
        handleClose();
    };

    const highlightNewColumns = (header) => {
        return (getExistingHeaders().findIndex((x) => x === toSnakeCase(header)) === -1) ? "highlight" : "";
    }
    useEffect(() => {
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            setIsLoading(false);
        }
    }, [data]);

    return (
        <Modal show={show} onHide={handleClose} size="lg" className="upload-mapper">
            <Modal.Header closeButton>
                <Modal.Title>Importing Voters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <small className="text-muted">To import your voter data, we will need you to map out the columns of your file to the existing fields.</small>
                    <br />
                    <small className="text-muted">Please select the column that corresponds to the field.</small>
                </p>
                <Form className="mt-3">
                    {sheetHeaders.length === 0 && <Form.Group>
                        <Form.Label>
                            <small>Click on the "Browse" button to select your voter data file.</small>
                        </Form.Label>
                        <Form.Control ref={inputRef} type="file" onChange={handleFileUpload} />
                    </Form.Group>}
                    <Stack gap={2}>
                        {
                            sheetHeaders.map((header, index) => (
                                <Form.Group key={index}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label>
                                                <small>{header}</small>
                                            </Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Select className={highlightNewColumns(mappedHeaders[index])} value={toSnakeCase(mappedHeaders[index] || "")} onChange={(e) => handleColumnMapping(e, index)}>
                                                <option value="">New Column</option>
                                                {
                                                    getExistingHeaders().map((hd, index) => (
                                                        <option key={index} value={hd}>{hd}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            ))
                        }
                    </Stack>
                </Form>
                {isLoading && <div className="text-center mt-3">
                    <Spinner animation="border" role="status" />
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleImport}>
                    {isLoading ? <Spinner animation="border" role="status" /> : 'Import'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VoterUploadMapper;
