import "./ManageUsers.css";
import { Badge, Button, Card, Col, Container, Row, Spinner, Stack, Table } from "react-bootstrap";
import { faFileImport, faFilter, faPencil, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate, debounce } from "helpers/index";
import { useUsers } from "hooks/useUsers";
import { v4 as uuidv4 } from "uuid";
import FilterUsers from "./FilterUsers/FilterUsers";
import UserUploadMapper from "./UserUploadMapper/UserUploadMapper";
import { useVariables } from "hooks/useVariables";
import UserModal from "components/Modals/UserModal/UserModal";
import { stripBraces } from "helpers/index";

function ManageUsers() {
    const [showUploadMapper, setShowUploadMapper] = useState(false);
    const [showFilterUsers, setShowFilterUsers] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState({ page: 1 });
    const {
        isLoading,
        total,
        users,
        usersCurrentPage,
        usersLastPage
    } = useUsers(filters);
    const { variables } = useVariables();
    const cardRef = useRef(null);
    const isLoadingRef = useRef(false);  // Track loading state with ref

    const handleFilterClose = useCallback((filters) => {
        setFilters({ ...filters, page: 1 });
        setUserList([]);
        setShowFilterUsers(false);
    }, []);

    const handleImportUsers = useCallback(() => {
        setShowUploadMapper(true);
    }, []);

    const handleReset = useCallback(() => {
        setFilters({ page: 1 });
        setUserList([]);
    }, []);

    const handleScroll = useCallback(() => {
        if (!cardRef.current || isLoadingRef.current) return;

        const { scrollHeight, clientHeight, scrollTop } = cardRef.current;
        const scrollThreshold = 100;

        const hasReachedThreshold = 
            scrollHeight - (clientHeight + scrollTop) <= scrollThreshold;

        if (hasReachedThreshold && usersCurrentPage < usersLastPage) {
            setFilters(prev => ({
                ...prev,
                page: prev.page + 1
            }));
        }
    }, [usersCurrentPage, usersLastPage]);

    // Attach scroll listener
    useEffect(() => {
        const currentRef = cardRef.current;
        if (currentRef) {
            const debouncedScroll = debounce(handleScroll, 150);
            currentRef.addEventListener('scroll', debouncedScroll);
            return () => {
                currentRef.removeEventListener('scroll', debouncedScroll);
                debouncedScroll.cancel();
            };
        }
    }, [handleScroll]);

    // Update loading ref when isLoading changes
    useEffect(() => {
        isLoadingRef.current = isLoading;
    }, [isLoading]);

    useEffect(() => {
        if (!users) return;

        setUserList(prevList => {
            if (filters.page === 1) {
                return users;
            }

            const uniqueUsers = users.filter(
                newUser => !prevList.some(
                    existingUser => existingUser.user_id === newUser.user_id)
            );

            const newList = [...prevList, ...uniqueUsers];

            // Ensure we don't exceed total
            return newList.slice(0, total);
        });
    }, [users, filters.page, total]);

    return (
        <section id="manageUsers">
            <Container fluid>
                <FilterUsers show={showFilterUsers} onHide={handleFilterClose} />
                <UserUploadMapper show={showUploadMapper} onHide={() => setShowUploadMapper(false)} />
                {selectedUser && <UserModal show={selectedUser} user={selectedUser} onHide={() => setSelectedUser(null)} />}
                <Row>
                    <Col>
                        <Card className="view">
                            <Card.Header>
                                <Stack className="d-flex justify-content-between" direction="horizontal" gap={2}>
                                    <div>
                                        <span>Manage Users</span>{" "}
                                        <Badge bg="success" size="sm">{userList.length}/{total}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleImportUsers}><FontAwesomeIcon icon={faFileImport} /></Button>{" "}
                                        {/* <Button variant="outline-success" size="sm"><FontAwesomeIcon icon={faPlus} /></Button>{" "} */}
                                        <Button variant="primary" size="sm" onClick={() => setShowFilterUsers(true)}><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper" ref={cardRef}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th colSpan={7}>Personal Information</th>
                                            <th colSpan={5}>Assignments</th>
                                            <th colSpan={variables.length}>Variables</th>
                                            <th className="sticky-col first-col" rowSpan={2}>Actions</th>
                                        </tr>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Sex</th>
                                            <th>Birthdate</th>
                                            <th>Contact Number</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Provinces</th>
                                            <th>Districts</th>
                                            <th>Municipalities/Cities</th>
                                            <th>Barangays</th>
                                            <th>Precincts</th>
                                            {
                                                variables.map((variable) => (
                                                    <th key={uuidv4()}>{variable.name}</th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((user, index) => (
                                                <tr key={user.user_id}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.sex}</td>
                                                    <td>{formatDate(user.birthdate)}</td>
                                                    <td>{user.contact_no}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.address}</td>
                                                    <td>{stripBraces(user.provinces)}</td>
                                                    <td>{stripBraces(user.districts)}</td>
                                                    <td>{stripBraces(user.municities)}</td>
                                                    <td>{stripBraces(user.barangays)}</td>
                                                    <td>{stripBraces(user.precincts)}</td>
                                                    {
                                                        variables.map((variable) => (
                                                            <td key={uuidv4()}></td>
                                                        ))
                                                    }
                                                    <td className="sticky-col first-col">
                                                        <Button size="sm" onClick={() => setSelectedUser(user)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <Stack gap={2} className="d-flex justify-content-center text-center">
                                    {isLoading && <Spinner animation="border" role="status" />}
                                    {!isLoading && userList.length === 0 && <span>No users found.</span>}
                                    {!isLoading && userList.length > 0 && usersCurrentPage === usersLastPage && <span>End of List</span>}
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ManageUsers;