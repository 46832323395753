import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack, Spinner, Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { getVoter, updateVoterAction } from "actions/voterActions.js";
import { variableSelector } from "selectors/variableSelector";
import { formatDate } from "helpers";
import { v4 as uuidv4 } from "uuid";
import { voterSelector } from "selectors/voterSelector";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";

function VoterModal(props) {
    const interview_id = uuidv4();
    const { onHide, show, voterId } = props;
    const [formData, setFormData] = useState({});
    const { barangays, muniCities, precincts, provinces, districts } = useGeopoliticalUnits({
        province: formData.province,
        district: formData.district,
        muniCity: formData.municity,
        barangay: formData.barangay,
        precinct: formData.precinct
    });
    const { variables } = useSelector(variableSelector);
    const { isLoading, voter } = useSelector(voterSelector);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleClose = () => {
        onHide();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(updateVoterAction(voter.voter_id, formData));
        handleClose();
    };

    const handleVoterResponseChange = (e) => {
        setFormData((prev) => {
            const responseIndex = formData.voter_responses.findIndex((res) => res.variable_id === e.target.name)
            if (responseIndex === -1) {
                return {
                    ...prev,
                    voter_responses: [...prev.voter_responses, { interview_id: interview_id, variable_id: e.target.name, answer: e.target.value }]
                };
            } else {
                return {
                    ...prev,
                    voter_responses: prev.voter_responses.map((res, index) => {
                        if (index === responseIndex) {
                            return { ...res, answer: e.target.value };
                        }
                        return res;
                    })
                };
            }
        })
    };

    useEffect(() => {
        if (formData) {
            console.log(formData)
        }
    }, [formData])

    useEffect(() => {
        dispatch(getVoter(voterId));
    }, [dispatch, voterId]);

    useEffect(() => {
        if (voter) {
            setFormData({ ...voter, voter_responses: [] });
        }
    }, [voter]);

    return (
        <Modal onHide={handleClose} show={show} size="xl" animation>
            <Modal.Header closeButton>Voter Profile</Modal.Header>
            <Modal.Body>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <Stack gap={2} className="mb-3">
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        onChange={handleChange}
                                        defaultValue={formData.name}
                                        required
                                        disabled={voterId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Sex</Form.Label>
                                    <Form.Select
                                        id="sex"
                                        name="sex"
                                        onChange={handleChange}
                                        value={formData.sex}
                                        required
                                        disabled={voterId && formData.sex}
                                    >
                                        <option value="">Select sex</option>
                                        {['M', 'F'].map((value) => (
                                            <option key={uuidv4()} value={value}>
                                                {value === 'M' ? 'Male' : 'Female'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a sex.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Birthdate</Form.Label>
                                    <Form.Control
                                        id="birthdate"
                                        name="birthdate"
                                        type="date"
                                        onChange={handleChange}
                                        defaultValue={formatDate(formData.birthdate)}
                                        required
                                        disabled={voterId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a birthdate.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    name="address"
                                    onChange={handleChange}
                                    defaultValue={formData.address}
                                    required
                                    disabled={voterId}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide an address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Province</Form.Label>
                                <Form.Select
                                    name="province"
                                    onChange={handleChange}
                                    value={formData.province}
                                    required
                                    disabled={voterId}
                                >
                                    <option value="">Select province</option>
                                    {provinces.map((province) => (
                                        <option key={uuidv4()} value={province}>
                                            {province}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select a province.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>District</Form.Label>
                                    <Form.Select
                                        name="province"
                                        onChange={handleChange}
                                        value={formData.district}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select province</option>
                                        {districts.map((district) => (
                                            <option key={uuidv4()} value={district}>
                                                {district}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a district.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Municipality</Form.Label>
                                    <Form.Select
                                        name="municipality"
                                        onChange={handleChange}
                                        value={formData.municity}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select municipality</option>
                                        {muniCities.map((municipality) => (
                                            <option key={uuidv4()} value={municipality}>
                                                {municipality}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a municipality.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Barangay</Form.Label>
                                    <Form.Select
                                        name="barangay"
                                        onChange={handleChange}
                                        value={formData.barangay}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a barangay.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Precinct</Form.Label>
                                    <Form.Select
                                        name="precinct"
                                        onChange={handleChange}
                                        value={formData.precinct}
                                        required
                                        disabled={voterId}
                                    >
                                        <option value="">Select precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a precinct.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <hr />
                            <Row>
                                {
                                    variables.map((variable) => {
                                        const response = voter?.voter_responses.find((res) => res.variable_id === variable.variable_id);
                                        const formDataResponse = formData.voter_responses?.find((res) => res.variable_id === variable.variable_id);
                                        return <Col key={uuidv4()} md={6}>
                                            <Form.Label className="text-muted"><small>{variable.name}</small></Form.Label>
                                            <Form.Control name={variable.variable_id} defaultValue={formDataResponse?.answer || response?.answer || ''} onBlur={handleVoterResponseChange} />
                                        </Col>
                                    })
                                }
                            </Row>
                        </Stack>
                        <Modal.Footer>
                            <Button variant="success" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        <span>Saving...</span>
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default VoterModal